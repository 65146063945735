<template>
  <div>
    <Card>
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <div>
            <h4>İlan Sayfa Tasarımı</h4>
          </div>

          <div class="form-group m-0">
            <!-- <label>Mesleği</label> -->
            <select
              name="delivery"
              class="form-control form-control-lg bg-white"
            >
              <option value="overnight" selected>bankamdan.com</option>
              <option value="Odebank">Odeabank</option>
              <option value="Fibanka">Fibanka</option>
              <option value="ING">ING</option>
              <option value="Ozel">Ozel</option>
              <option value="Diger">Diger</option>
              <option value="Teb">Teb</option>
              <option value="AlternatifBank">Alternatifbank</option>
              <option value="Burgan">Burgan</option>
              <option value="Sahibinden">Sahibinden</option>
              <option value="Burganleasing">Burganleasing</option>
              <option value="Sekerbank">Sekerbank</option>
              <option value="TurkiyeFinans">TurkiyeFinans</option>
              <option value="Alternatiffleasing">Alternatiffleasing</option>
              <option value="Albaraka">Albaraka</option>
              <option value="Anadolubank">Anadolubank</option>
              <option value="Anadoluleasing">Anadoluleasing</option>
              <option value="Sekerfinans">Sekerfinans</option>
              <option value="Istanbulvarlik">Istanbulvarlik</option>
              <option value="Ismailoglugayrimenkul">
                Ismailoglugayrimenkul
              </option>
              <option value="Noktagayrimenkul">Noktagayrimenkul</option>
              <option value="Vakifbank">Vakifbank</option>
              <option value="Perabank">Perabank</option>
            </select>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-sm-6 col-md-3 design-description">
          <label class="d-flex align-items-center">
            <img
              src="../../../public/image/ilan1.png"
              alt="Option 1"
              width="100"
            />
            <input
              type="radio"
              name="test"
              value="small"
              checked
              style="margin-left: 10px"
            />
            <span style="font-size: 13px" class="ml-2">1 Büyük 4 Küçük</span>
          </label>
        </div>
        <div class="col-sm-6 col-md-3 design-description">
          <label class="d-flex align-items-center">
            <img
              src="../../../public/image/ilan2.png"
              alt="Option 1"
              width="100"
            />
            <input
              type="radio"
              name="test"
              value="small"
              checked
              style="margin-left: 10px"
            />
            <span style="font-size: 13px" class="ml-2">2 Büyük 4 Küçük</span>
          </label>
        </div>
        <div class="col-sm-6 col-md-3 design-description">
          <label class="d-flex align-items-center">
            <img
              src="../../../public/image/ilan3.png"
              alt="Option 1"
              width="100"
            />
            <input
              type="radio"
              name="test"
              value="small"
              checked
              style="margin-left: 10px"
            />
            <span style="font-size: 13px" class="ml-2">2 Büyük X 4 Küçük </span>
          </label>
        </div>
        <div class="col-sm-6 col-md-3 design-description">
          <label class="d-flex align-items-center">
            <img
              src="../../../public/image/ilan4.png"
              alt="Option 1"
              width="100"
            />
            <input
              type="radio"
              name="test"
              value="small"
              checked
              style="margin-left: 10px"
            />
            <span style="font-size: 13px" class="ml-2">8 Küçük</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="twoBoxes-1 col-md-6">
          <div class="twoBoxes-title-1">
            <h4>Önerilen İlanlar</h4>
          </div>
          <div class="twoBoxes-1-list">
            <ul class="list-group">
              <li
                v-for="ilan in ilanlar"
                :key="ilan.id"
                class=""
                @dblclick="setSelectedIlan(ilan)"
              >
                {{ ilan.text }}
              </li>
            </ul>
          </div>
        </div>

        <div class="twoBoxes-1 col-md-6">
          <div class="twoBoxes-title-1">
            <h4>Gösterilen İlanlar</h4>
          </div>
          <div class="twoBoxes-1-list">
            <ul>
              <li
                v-for="ilan in selectedIlan"
                :key="ilan.id"
                class=""
                @dblclick="removeSelectedItem(ilan)"
              >
                {{ ilan.text }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="d-flex align-items-center justify-content-between mt-4 DesignShowBtn"
      >
        <label>
          <input
            type="radio"
            name="test"
            value="small"
            checked
            style="margin-left: 10px"
          />
          <span style="font-size: 15px; font-weight: 500"
            >Seçilen Sırada Göster</span
          >
        </label>
        <label>
          <input
            type="radio"
            name="test"
            value="small"
            checked
            style="margin-left: 10px"
          />
          <span style="font-size: 15px; font-weight: 500"
            >Güncellenme tarihine göre göster</span
          >
        </label>
        <label>
          <input
            type="radio"
            name="test"
            value="small"
            checked
            style="margin-left: 10px"
          />
          <span style="font-size: 15px; font-weight: 500"
            >Rastgele sırada göster</span
          >
        </label>
      </div>

      <button class="saveButton mx-auto mt-5">Kaydet</button>
    </Card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "İlan Tasarım Sayfası", route: "/adverts/design" },
      ]);
    },
  data() {
    return {
      ilanlar: [
        { id: 1, text: "Türkiye Finans'tan Bilecik Merkez'de 136 m² Depo" },
        { id: 2, text: "Konya Selçuklu'da 166 m² Dükkan" },
        {
          id: 3,
          text: "Türkiye Finans'tan Diyarbakır Bismil'de 185 m² 3+1 Daire",
        },
        { id: 4, text: "Sahibinden Ankara Şereflikoçhisar'da 650 m² Arsa" },
        { id: 5, text: "Sahibinden Afyonkarahisar Sandıklı'da 408 m² Arsa" },
      ],
      selectedIlan: [],
    };
  },
  
  methods: {
    selectItem(item) {
      // Öğeyi seçilen öğeler dizisine ekleyin
      this.selectedItems.push(item);

      // Orijinal listeden kaldırın
      const index = this.list.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        this.list.splice(index, 1);
      }
    },
    setSelectedIlan(item) {
      let findInList = this.selectedIlan.filter((i) => i.id === item.id);
      if (findInList.length === 0) {
        this.selectedIlan = [item, ...this.selectedIlan];
      }
    },

    removeSelectedItem(ilan) {
      const index = this.selectedIlan.findIndex((item) => item.id === ilan.id);
      if (index !== -1) {
        this.selectedIlan.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  color: $dveb-primary;
  font-size: 1.23077rem;
  font-weight: 600;
  letter-spacing: 0.00769rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & {
    flex: 1;
  }
}

.show > .btn.btn-outline-light.dropdown-toggle {
  background-color: #fff;
}
.twoBoxes-1-list li {
  list-style: none;
  border-bottom: 1px solid #99999982;
  width: 88%;
  padding: 5px;
  cursor: pointer;
}
.twoBoxes-1 {
  margin-top: 60px;
}
@media screen and (max-width: 750px) {
  .DesignShowBtn {
    font-size: 12px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}
</style>
